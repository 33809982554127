<template>
  <loader-content v-if="loading" />

  <div v-else>
    <v-card class="shadow rounded-lg pa-4 mb-6">
      <v-list v-if="contracts.length > 0" class="transparent pa-0" nav>
        <v-list-item v-for="(item, i) in contracts" :key="i">
          <v-row>
            <v-col cols="3" md="2" lg="1">
              <div class="text-body-1">
                <span
                  v-if="item.vencido == 0"
                  class="green--text font-weight-bold"
                >
                  Ativo
                </span>

                <span v-else class="red--text font-weight-bold">
                  Expirado
                </span>
              </div>
              <div class="text-body-2 subtext--text">Status</div>
            </v-col>

            <v-col cols="9" md="4" lg="5">
              <div class="text-body-1">
                {{ item.nomeContrato }} #{{ item.id }}
              </div>
              <div class="text-body-2 subtext--text">
                {{ item.dataContrato }}
              </div>
            </v-col>

            <v-col cols="6" md="3">
              <div class="text-body-1">
                {{ currencyMask(item.saldoAtual) }}
                <span class="text-caption">BRL</span>
              </div>
              <div class="text-body-2 subtext--text">Valor atual</div>
            </v-col>

            <v-col cols="6" md="3">
              <div class="text-body-1">
                {{ currencyMask(item.totalSacado) }}
                <span class="text-caption">BRL</span>
              </div>
              <div class="text-body-2 subtext--text">Valor sacado</div>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>

      <div v-else class="d-flex text-center justify-center">
        <span class="text-body-2 subtext--text">
          Este usuário não possui nenhum contrato no momento
        </span>
      </div>
    </v-card>

    <!-- pagination -->
    <v-pagination
      v-model="pagination.page"
      color="primary"
      :length="pagination.pageCount"
      :total-visible="10"
      @input="getData()"
    />
  </div>
</template>

<script>
import { request } from "@/services";
import { currencyMask, handlePagination } from "@/utils";

export default {
  data() {
    return {
      loading: true,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },
      contracts: [],
    };
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        const payload = {
          method: "listUserContratosAdmin",
          idCliente: this.$route.params.id,
          page: this.pagination.page,
        };

        await request(payload).then(async (res) => {
          this.contracts = res.userContratos;
          this.pagination.pageCount = handlePagination(res.total);
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    currencyMask,
  },
};
</script>

<style></style>
